<template>
  <transition name="fade" mode="out-in">
    <contacting-mc-page
      v-if="!messageSend"
      :user="getUSER"
      :timer="timer"
      :show-checking="showChecking"
      :files="files"
      @timer="setTimer"
      @onSubmit="onSubmit"
      @onSend="onJustSend"
      @verified="onVerified"
      @onFileLoad="onFileLoad"
      @onFileDelete="onFileDelete"
    />
    <div class="documents" v-else>
      <div class="form-container">
        <h1>
          Уважаемый, {{ `${getUSER.userName.name} ${getUSER.userName.mname}` }}
        </h1>
        <h3>
          Спасибо, Ваше обращение отправлено.<br />
          Мы свяжемся с Вами в ближайшее время.
        </h3>
        <div class="row">
          <div class="col-3">
            <rwm-button outlined width="100%" @click="newOne()"
              >Новое сообщение</rwm-button
            >
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContactingMcPage from "../../components/pages/cabinet/ContactingMCPage";
import RwmButton from "../../components/RWMButton/RwmButton";

export default {
  name: "ContactingMcView",
  components: { RwmButton, ContactingMcPage },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
  },
  data() {
    return {
      timer: false,
      showChecking: false,
      files: [],
      messageSend: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("Messages", ["SEND_NOTYFICATION"]),
    setTimer(val) {
      this.timer = val;
    },
    onSubmit({ isFull }) {
      if (isFull) {
        this.REQUEST_SMS({ phone: this.getUSER.phone, action: 'feedback' }).then((success) => {
          if (success) {
            this.timer = true;
            this.showChecking = true;
          } else this.getERROR ? this.$toast.error(this.getERROR) : "";
        });
      } else {
        this.$toast.error("Вы не можете отправить пустое сообщение");
      }
    },
    onVerified({ code, form }) {
      this.CONFIRM_SMS({ phone: this.getUSER.phone, key: code }).then(
        (success) => {
          if (success) {
            this.SEND_NOTYFICATION(form)
            this.$toast.success("Обращение отправлено");
            this.messageSend = true;
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    onJustSend(form) {
      if (form.message?.length > 0) {
        this.SEND_NOTYFICATION(form)
        this.$toast.success("Обращение отправлено");
        this.messageSend = true;
      } else {
        this.$toast.error("Вы не можете отправить пустое сообщение");
      }
    },
    onFileLoad(e) {
      let file = e.target.files[0];
      if (file.size > 5242880) {
        this.$toast.error("Размер файла превышает 5mb");
      } else {
        this.files.push(file);
      }
    },
    onFileDelete(file) {
      this.files = this.files.filter((f) => f.name !== file.name);
    },
    newOne() {
      this.messageSend = false;
      this.showChecking = false;
      this.files.length = 0;
    },
  },
};
</script>
