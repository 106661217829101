<template>
  <div class="documents">
    <div class="form-container">
      <h1>Написать в УК</h1>
      <div class="content">
        <h3>
          <span class="normal">От:</span>
          {{
            `${user.userName.sname} ${user.userName.name} ${user.userName.mname}`
          }}
        </h3>
        <rwm-text-field
          textarea
          type="text"
          label="Текст сообщения"
          height="400px"
          v-model="message"
        />
        <template v-if="files">
          <rwm-file-loaded
            v-for="file in files"
            :key="file.id"
            width="360px"
            :filename="`${file.name} (${getSize(file.size)})`"
            @deleteFile="onFileDelete(file)"
          />
        </template>
      </div>
      <div class="upload-block anketa">
        <div class="btn" @click="loadFile('refer')">
          <v-icon>upload-doc</v-icon>
          <p>Загрузить файл</p>
        </div>
        <input
          :disabled="disabled"
          type="file"
          accept="image/jpeg, image/gif, image/png, application/pdf"
          ref="refer"
          @change="onFileChange"
        />
        <div class="col-12 info">
          <p>Допустимые форматы файлов: pdf, jpg, png.</p>
          <p>Максимальное количество файлов: не более 5.</p>
          <p>Максимальный размер каждого файла: 5 Мб.</p>
        </div>
      </div>
      <rwm-checkbox label="Отправить документы с ПЭП" v-model="readyToSubmit" />
      <div class="row">
        <div class="col-3">
          <rwm-button width="100%" @click="submit()">{{
            readyToSubmit ? "Подписать" : "Отправить"
          }}</rwm-button>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <rwm-sms-checking
            v-model="_timer"
            :time="60"
            field-width="150"
            @sendAgain="submit()"
            @verified="codeIsVerified"
            :isShow="showChecking"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RwmFileLoaded from "../../RWMInputFile/RwmFileLoaded";
import VIcon from "../../icons/VIcon";
import RwmCheckbox from "../../RWMCheckbox/RwmCheckbox";
import RwmSmsChecking from "../../RWMSMSChecking/RwmSmsChecking";
import RwmButton from "../../RWMButton/RwmButton";
import RwmTextField from "../../RWMTextField/RwmTextField";
export default {
  name: "ContactingMcPage",
  components: {
    RwmTextField,
    RwmButton,
    RwmSmsChecking,
    RwmCheckbox,
    VIcon,
    RwmFileLoaded,
  },
  props: {
    user: Object,
    timer: Boolean,
    showChecking: Boolean,
    disabled: Boolean,
    files: Array,
  },
  computed: {
    _timer: {
      get() {
        return this.timer;
      },
      set(val) {
        this.$emit("timer", val);
      },
    },
  },
  data() {
    return {
      readyToSubmit: false,
      message: "",
    };
  },
  methods: {
    submit() {
      if (this.readyToSubmit) this.$emit("onSubmit", {isFull: this.message.length > 0});
      else
        this.$emit("onSend", {
          message: this.message,
          files: this.files,
        });
    },
    codeIsVerified(code) {
      let form = {
        message: this.message,
        files: this.files,
      };
      this.$emit("verified", { code: code, form: form }); //sending data here
    },
    loadFile(ref) {
      this.$refs[ref].click();
    },
    onFileChange(e) {
      this.$emit("onFileLoad", e);
    },
    onFileDelete(file) {
      this.$emit("onFileDelete", file);
    },
    getSize(size) {
      if (Math.floor(size / 1000000) <= 0) {
        return `${Math.ceil(size / 100) / 10} кб`;
      } else {
        return `${Math.ceil(size / 1000) / 1000} мб`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.normal {
  font-weight: normal;
}
.content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.upload-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 12px;
  transition: all 300ms;
  input {
    display: none;
  }
  & .btn {
    column-gap: 32px;
    display: flex;
    cursor: pointer;
    &:hover {
      color: $RWM-red;
    }
  }
  @media screen and (min-width: 600px) {
    max-width: max-content;
  }

  & .info {
    font-size: 16px;
    cursor: auto;
    color: $RWM-grey;
  }
}
</style>
